@import "bootstrap/scss/breadcrumb";

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f054" !important;
  font-family: "Font Awesome 6 Free", serif;
  font-weight: 900;
}

.breadcrumb-item{
  font-family: $secondary-font-family;
  color:#2E2C2F;
  font-weight: bold;
  font-size: 15px;
  a{
    text-decoration: none;
  }
}

.breadcrumb{
  margin-bottom: 0;
  --bs-breadcrumb-item-padding-x: 5px;
}

.fc-white{
  .breadcrumb-item + .breadcrumb-item::before {
    color: inherit !important;
  }

  .breadcrumb-item{
    color: inherit !important;
    a{
      color: inherit !important;
    }
  }
}
