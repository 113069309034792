.navigation-card {

  background-color: #F8F8F8;
  position: sticky;
  top: 20px;

  &-accordion {
    background-color: #F8F8F8;

    .accordion-button {
      font-size: 20px;
      line-height: 29px;
      background-color: #F8F8F8;

      &::after {
        width: 18.75px;
        height: 18.75px;
        background-size: 18.75px;
      }
    }

    .accordion-item {
      background-color: #F8F8F8;
    }

    .accordion-body {
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        font-family: $secondary-font-family;
        font-size: 20px;
        line-height: 29px;
        font-weight: bold;

        li {
          a {
            text-decoration: none;
          }

          a.active {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
