.cart {

  &-header {
    font-family: $secondary-font-family;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid black;
  }

  &-footer {
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
    font-size: 20px;
  }

  &-buttons {
    button {
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
      border: 1px solid $dark;
      border-radius: 38px;
      padding: 9px 30px;

      i {
        font-size: 20px;
        color: $dark;
      }

      &:hover {
        background-color: $dark;
        color: $white;

        i {
          color: $white;
        }
      }

    }

    &-back {
      background-color: $white;
    }

    &-checkout {
      background-color: $orange;
    }
  }
}

@media (max-width: 768px) {
  .cart {
    &-header {
      display: none;
    }

    &-buttons {
      button {
        width: 100%;
      }
    }

  }
}