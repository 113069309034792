.image-card{
  background: url('holder.js/400x512?auto=yes&textmode=exact&bg=#70747a&text=%20') no-repeat center center;
  height:512px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title{
    color: #fff;
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 30px;
    line-height: 43px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  &-buttons{
    display: flex;
    flex-direction: column;
  }

  &-button {
    margin-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .image-card {
    margin-bottom: 15px;
  }
}
