.header {
  &-banner {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: #FDDAB2;

    color: #2E2C2F;
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
  }

  &-navigation {
    height: 100px;
    display: flex;
    align-items: center;

    &-left {
      .contact {
        &-phone {
          display: flex;
          font-family: "Brandon Grotesque", sans-serif;
          font-size: 30px;
          line-height: 43px;
          font-weight: bold;
          align-items: center;

          i {
            font-size: 25px;
            margin-right: 10px;
          }
        }

        &-details {
          font-family: 'Lora', serif;
          font-size: 15px;
          font-weight: bold;

          a {
            color: #2E2C2F;
          }
        }
      }

      &-tablet {
        display: none;

        i {
          font-size: 25px;
          margin-bottom: 5px;
        }

        p {
          font-family: "Brandon Grotesque", sans-serif;
          font-size: 15px;
          line-height: 22px;
          font-weight: bold;
          color: #2E2C2F;
          margin: 0;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          color: #2E2C2F;
        }

        a:first-child {
          margin-right: 30px;
        }

      }

      &-mobile {
        display: none;

        i {
          font-size: 20px;
          margin-right: 8px;
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #2E2C2F;
        }

        a:first-child {
          margin-right: 30px;
        }

      }
    }

    &-center {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-right {
      height: 100%;
      display: flex;
      align-items: center;

      .account-options {
        display: flex;
        align-items: center;

        .sign-in {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          text-decoration: none;
          font-weight: bold;
          color: #2E2C2F;
        }

        .liked {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
          position: relative;
          text-decoration: none;
          font-weight: bold;
          color: #2E2C2F;
        }

        .cart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          text-decoration: none;
          font-weight: bold;
          color: #2E2C2F;
        }

        &-count {
          position: absolute;
          color: #2E2C2F;
          font-family: "Brandon Grotesque", sans-serif;
          font-size: 9px;
          font-weight: bold;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F98400;
          top: -4px;
          right: 0;
          border-radius: 50%;
        }

        i {
          font-size: 25px;
          margin-bottom: 5px;
        }
      }
    }

  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #154C6C;
    position: relative;

    ul {
      list-style: none;
    }

    &-item {
      color: #FFFFFF;
      font-family: "Brandon Grotesque", sans-serif;
      font-size: 20px;
      line-height: 43px;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      padding-bottom: 20px;

      &:hover {
        color: #F98400;
      }
    }

    .active {
      color: #F98400;
    }

    &-subMenu {
      position: absolute;
      left: 0;
      width: 100%;
      min-height: 300px;
      background-color: #F8F8F8;
      z-index: 9999999;

      ul {
        &:not(:last-child){
          margin-bottom: 30px !important;
        }
        li {
          font-size: 15px;
          line-height: 22px;

          a {
            &:hover {
              text-decoration: underline !important;
            }
          }
        }
      }

      .column-special{
        background-color:#FDDAB2;
      }
    }
  }

  &-logo {
    width: 260px;
    height: 45px;
  }

  .search-bar {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #2E2C2F;
    border-radius: 24px;
    display: flex;
    align-items: center;
    padding: 9px 30px 9px 20px;
    box-sizing: border-box;
    margin-right: 44px;
    max-height: 47px;

    .search-input {
      flex: 1;
      border: none;
      outline: none;
      width: calc(6ch + 10px);
      font-family: 'Lora', sans-serif;
      font-size: 18px;
      line-height: 27px;
      font-weight: normal;
      color: #2E2C2F;
      padding: 0 10px;
      background-color: transparent;
      margin-right: 15px;

      &::placeholder {
        color: #2E2C2F;
        opacity: 0.6;
      }
    }

    i {
      font-size: 20px;
      color: #2E2C2F;
      cursor: pointer;
    }
  }
}

.menu-sidebar{
  width: 100% !important;
  max-width: 1200px !important;
  top: 170px !important;
  background-color: #F8F8F8;
  &-header{
    justify-content: center;
    padding-top: 20px;

    i:first-child{
      position: absolute;
      left: 20px;
      font-size: 30px;
    }

    i:last-child{
      position: absolute;
      right: 20px;
      font-size: 30px;
    }
  }

  &-body{
    padding-left: 20px;
    padding-right: 20px;

    &-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $dark;
      cursor: pointer;

      &:last-child{
        border-bottom: 1px solid $dark;
      }

      i{
        font-size: 20px;
      }
    }

    a{
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
    }
  }

  .slide-left-enter {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  .slide-left-enter-active {
    transform: translateX(0);
    opacity: 1;
  }

  .slide-left-exit {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  .slide-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
  }

  .slide-right-enter {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  .slide-right-enter-active {
    transform: translateX(0);
    opacity: 1;
  }

  .slide-right-exit {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  .slide-right-exit-active {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .header {
    &-logo {
      width: 200px;
      height: 35px;
    }


  }
  .menu-sidebar{
    top: 190px !important;
  }
}

@media (min-width: 440px) and (max-width: 767.98px) {
  .header {
    &-logo {
      width: calc(116px + (200 - 116) * ((100vw - 350px) / (768 - 350))) !important;
      height: calc(20px + (35 - 20) * ((100vw - 350px) / (768 - 350))) !important;
    }
  }
}

@media (max-width: 767.98px) {
  .header {
    &-logo {
      width: 116px;
      height: 20px;
    }

    &-banner {
      font-size: 15px;
      line-height: 22px;
    }

    &-navigation {
      height: 60px;

      &-right {

        .account-options {

          .sign-in {
            margin-right: 10px;
          }

          .liked {
            margin-right: 10px;
          }

          &-description {
            display: none;
          }

          &-count {
            width: 12px;
            height: 12px;
            font-size: 8px;
          }

          i {
            font-size: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }

    &-menu {
      height: 71px;
    }
  }
}

