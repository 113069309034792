.footer {
  background-color: #475B63;
  min-height: 460px;
  color: white;
  padding: 40px 0;

  a {
    color: #ffffff;
    text-decoration: none;
    font-family: "Lora", sans-serif;
    font-weight: normal;

    &:hover {
      color: #d1d1d1;
    }
  }

  p {
    color: #ffffff;
    line-height: 21px;
  }

  ul li {
    margin-bottom: 19px;
  }

  &-headers {
    font-size: 20px;
    color: #FFFFFF;
    font-family: "Brandon Grotesque", sans-serif;
    margin-bottom: 20px;
    font-weight: bold;
  }

  hr {
    background-color: #ffffff;
    border: 1px solid #FFFFFF;
    margin-bottom: 40px;
  }

  &-social-media i {
    font-size: 30px;
    margin-right: 15px;
  }

  &-card {
    margin-bottom: 40px;
  }
}
