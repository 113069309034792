.my-account{
  &-card{
    background-color:#F8F8F8 ;

    &-redirect{
      border:1px solid $dark;
      border-radius:38px;
      font-family: $secondary-font-family;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      padding: 7px 30px;

      &:hover{
        background-color: $dark;
        color:$white;
      }
    }
  }
  &-new-sign-up{
    background-color:#FDDAB2 ;
  }
}