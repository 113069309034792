.sizeChart{
  h1:first-child{
    margin-bottom: 10px;
  }
  p{
    margin-bottom: 30px;
  }

  #sizeChartFrame{
    padding: 15px 20px 35px 20px;
  }

  table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  .sizes{

    thead{

      tr:first-child{
          font-family: $secondary-font-family;
          font-size: 25px;
          line-height: 36px;
          font-weight: bold;
          margin-bottom: 10px;
      }

      tr:not(:first-child){
        font-family: $secondary-font-family;
        font-size: 20px;
        line-height: 29px;
        font-weight: bold;
        padding-bottom:10px ;
        border-bottom: 1px solid $dark;
      }
    }

    tbody{

      tr{
        font-family: $primary-font-family;
        font-size: 18px;
        line-height: 27px;
        font-weight: normal;
        border-bottom: 1px solid $dark;

        td{
          padding-bottom: 15px;
          padding-top: 15px;
        }
      }
    }
  }
}
