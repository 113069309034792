@import "bootstrap/scss/offcanvas";

@media (max-width: 550px){
  .offcanvas.offcanvas-start{
    border-right: none;
  }

  .offcanvas.offcanvas-end{
    border-left: none;
  }
}
