.notification {
  position: fixed;
  top: 40px;
  right: 20px;
  background-color: #444;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: $secondary-font-family;
  font-size: 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.notification i {
  margin-right: 10px;
  font-size: 22px;
}

.notification.success {
  background-color: $green;
}

.notification.info {
  background-color: $blue;
}

.notification {
  transform: translateY(-20px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.notification.fadeIn {
  transform: translateY(0);
  opacity: 1;
}
