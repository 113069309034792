.filter-sidebar {
  width: 550px !important;
  background-color: #F8F8F8 !important;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 30px 20px;

    i {
      font-size: 30px;
      line-height: 36px;
      color: $dark;
    }
  }

  &-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;

    .filter-accordion {
      background: #F8F8F8;

      .accordion-item {
        background: #F8F8F8;
      }

      .accordion-button {
        background: #F8F8F8;
        &::after {
          width: 20px;
          height: 20px;
          background-size: 20px;
        }
      }
    }

    .filter-form-check {

      display: flex;
      align-items: center;

      &-input {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 0.25rem;
        margin-right:10px;

        &:checked[type="checkbox"] {
          background-color: $white;
          border: 1px solid $dark;

          &:after {
            content: '\f00c';
            font-family: 'Font Awesome 6 Free';
            font-weight: 900;
            font-size: 15px;
            color: $dark;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &-label{
        font-family: $secondary-font-family;
        font-size: 15px;
        font-weight: bold;
        line-height: 22px;
      }
    }
  }

  &-submit {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    z-index: 10;

    button {
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
      color: $dark;
      width: 100%;
      background-color: $orange;
      padding: 7px 0;
      border: 1px solid $dark;
      border-radius: 38px;
      transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

      i {
        font-size: 20px;
        color: $dark;
        transition: color 0.3s ease;
      }

      &:hover {
        background-color: $dark;
        color: $white;

        i {
          color: $white;
        }
      }
    }
  }
}