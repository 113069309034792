.cart-product {
  &-description {
    display: flex;
  }

  &-image {
    margin-right: 15px;
    padding: 20px;
    background: #F8F8F8;
    height: 121px;
    width: 121px;

    img {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  &-quantity {
    &-input {
      &-group {
        display: flex;
        align-items: center;
        border: 1px solid $dark;
        border-radius: 30px;
        width: fit-content;

        button {
          background: none;
          border: none;
          padding: 9px 13px;
          cursor: pointer;
          font-size: 20px;
          color: #555;

          i {
            font-size: 15px;
            color: $dark;
          }

          &:focus {
            outline: none;
          }
        }

        input {
          width: 30px;
          height: 40px;
          border: none;
          text-align: center;
          font-family: $secondary-font-family;
          font-size: 15px;
          font-weight: bold;
          padding: 0;
          margin: 0;

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  &-remove {
    height: 40px;
    width: 40px;
    border: 1px solid $dark;
    background-color: $dark;
    border-radius: 30px;

    i {
      color: $white;
      font-size: 15px;
    }
  }
}

@media (max-width: 768px) {
  .cart-product {
    &:first-child {
      border-top: 1px solid $dark;
    }

    &:not(:last-child){
      border-bottom: 1px solid $dark;
    }

    &-description {
      border-bottom: 1px solid #EEEEEE;
    }
  }
}