.category-card {
  position: relative;
  height: 512px;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    z-index: 0;
  }

  &-content {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  &-name {
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }

    a{
      border: 1px solid $dark;
      font-size: 20px;
      font-family: $secondary-font-family;
      line-height: 29px;
      padding: 7px 30px;
      &:hover{
        border: 1px solid $white;
        background-color:$dark;
        color: $white;
      }
    }
  }
}