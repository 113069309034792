.product {

  &-showcase {
    position: relative;

    &-primary {
      margin-bottom: 15px;
      position: relative;
      display: block;
      padding: 35px;
      background-color:#eeeeee;

      i {
        position: absolute;
        right: 36px;
        bottom: 36px;
        font-size: 30px;
        cursor: pointer;
        z-index: 20;
      }
    }

    &-secondary {
      display: block;
      margin-bottom: 15px;
      padding: 20px;
      background-color:#eeeeee;

      img{
        width: 100%;
      }
    }

    &-mobile {
      display: none;
      position: relative;
      margin-bottom: 75px;

      img{
        height: 350px !important;
      }

      .swiper-slide {
        display: flex;
        justify-content: center;

        .image-container {
          position: relative;

          i {
            position: absolute;
            bottom: 36px;
            right: 36px;
            font-size: 30px;
          }
        }
      }

      .swiper-pagination {
        font-family: "Brandon Grotesque", sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 29px;
        bottom: -45px;

      }

      .swiper-button-next,
      .swiper-button-prev {
        font-size: 20px !important;
        bottom: -53px !important;
        top: auto !important;
        z-index: 11;
      }

      .swiper-button-next:after {
        font-size: 20px !important;
      }

      .swiper-button-prev:after {
        font-size: 20px !important;
      }

      .swiper-button-next {
        right: 35%;
      }

      .swiper-button-prev {
        left: 35%;
      }

    }
  }

  &-sale-ribbon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    width: 80px;
    height: 80px;
    clip-path: polygon(100% 0, 100% 100%, 0 0);
    z-index: 10;

    &-text {
      position: absolute;
      top: 9px;
      right: 7px;
      color: #fff;
      font-family: "Brandon Grotesque", "Helvetica Nue", Helvetica, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      transform: rotate(45deg);
    }
  }

  &-content {
    p{
      font-size: 18px;
      line-height: 27px;
    }

    &-price {
      display: flex;
      align-items: center;

      h4 {
        margin-right: 14px;
      }
    }

    &-discount {
      color: #fff;
      font-family: "Brandon Grotesque", sans-serif;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;

      background-color: #154C6C;
      padding: 2px 4px;
      border-radius: 4px;
    }

    &-colors {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      input[type="radio"] {
        display: none;
      }

      label {
        cursor: pointer;
        display: inline-flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        border: 1px solid #2E2C2F;
        padding: 10px;
        background-color: #eeeeee;
      }

      input[type="radio"]:checked + label {
        outline: 3px solid #2E2C2F;
      }

      input[type="radio"]:disabled + label {
        opacity: .6;
      }
    }

    &-variables{
      border-top: 1px solid #2E2C2F;
      padding-top: 25px;
    }

    &-sizes {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;
      gap: 5px;

      input[type="radio"] {
        display: none;
      }

      label {
        position: relative;
        background-color: #EEEEEE;
        border-radius: 30px;
        border: 1px solid #2E2C2F;
        padding: 8px 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        min-width: 90px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        font-family: "Brandon Grotesque", sans-serif;
        font-weight: bold;

        &:hover {
          background-color: #2E2C2F;
          color: white;
        }
      }

      &-guide {
        i {
          font-size: 20px;
        }

        a {
          font-size: 20px;
        }
      }

      .unavailable {
        position: relative;
        cursor: not-allowed;

        &::before {
          content: "";
          position: absolute;
          top: 17px;
          left: -3px;
          width: 96%;
          height: 100%;
          border-top: 1px solid #2E2C2F;
          transform: rotate(19deg);
          pointer-events: none;
        }

        &:hover {
          background-color: #EEEEEE !important;
          color: #2E2C2F !important;
        }
      }

      input[type="radio"]:checked + label {
        background-color: #2E2C2F;
        color: white;
        border: 1px solid #2E2C2F;
      }

      input[type="radio"]:disabled + label {
        position: relative;
        overflow: hidden;
        opacity: .6;

        &:after {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #2E2C2F;
          content: '';
          transform: rotate(19deg);
          z-index: 10;
        }
      }
    }

    &-available{
      i{
        font-size: 20px;
        color: #29B600;
      }
    }

    &-unavailable{
      i{
        font-size: 20px;
        color: #FF0000;
      }
    }

    &-submit {
      width: 100%;
      background: #F98400;
      border: 1px solid #2E2C2F;
      border-radius: 38px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 25px;
      font-family: "Brandon Grotesque", sans-serif;
      line-height: 36px;
      font-weight: bold;

      &:hover{
        background-color: $dark;
        color: $white;

        i{
          color: $white;
        }
      }

      &:disabled {
        opacity: 0.3;
        color: #2E2C2F;
        background-color: #F98400 !important;
        &:hover{
          background-color: #C0BFC0;
          color: $white;
          opacity: 1;
          border-color: #C0BFC0;

          i{
            color: $white;
          }
        }
      }
    }

    &-trustpilot {
      margin-bottom: 40px;
    }

    &-postdetails {
      i {
        font-size: 25px;
      }
    }
  }

  &-notification{
    background-color: #F8F8F8;

    &-header{
      i{
        font-size: 30px;
        line-height: 36px;
      }
    }
    &-body{

      &-variable{
        font-family: $secondary-font-family;
        font-size: 15px;
        line-height: 22px;
      }
    }
    &-form{

      label{
        font-family: $secondary-font-family;
        font-size: 20px;
        font-weight: bold;
        line-height: 29px;
      }

      input{
        border-radius: 0;
        border: 1px solid $dark;
        padding:12px;
        font-family: $primary-font-family;
        font-size: 18px;
        font-weight: normal;
        line-height: 27px;
      }

      &-btn{
        padding: 7px 30px;
        font-family: $secondary-font-family;
        font-size: 20px;
        line-height: 29px;
        font-weight: bold;
        border: 1px solid $dark;
        border-radius: 38px;
        &:hover{
          border-color: $dark;
          background-color: $dark;
          color:$white;
        }
      }
    }
  }
}

.prod-attr{
  font-size: 1.25rem;
}


@media (max-width: 768px) {
  .breadcrumb-section {
    display: none;
  }

  .product {

    &-showcase {
      display: none;

      &-mobile {
        display: block;
      }
    }

    &-accordion {
      margin-bottom: 80px;
    }

  }
}

@media (min-width: 768px){
  .sticky-column {
    position: sticky;
    top: 20px;
    height: fit-content;
  }
}
