.home {

  section {
    margin-bottom: 60px;
  }

  section:nth-child(2) {
    padding-top: 40px;
  }

  .intro-image{
    margin-right: 10px;
  }

  .heading {
    &-section {
      background: url("holder.js/1920x550?auto=yes&textmode=exact&bg=#6a839f&text=%20") no-repeat center center;
      background-size: 100% 550px !important;
      height: 550px;
      width: 100%;
    }

    &-inner-container{
      position: relative;
      height: 550px;
    }

    &-content{
      position: absolute;
      left: 0;
      bottom: 125px;
    }

    &-title{
      color:#FFFFFF;
      font-family: "Brandon Grotesque", sans-serif;
      font-size: 80px;
      font-weight: bold;
      line-height: 80px;
      margin-bottom: 10px;
    }

    &-description{
      color:#FFFFFF;
      font-family: Lora, sans-serif;
      font-size: 25px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 50px;
    }

    &-button{
      background-color: #F98400;
      color:#2E2C2F;
      border: 1px solid #2E2C2F;
      border-radius: 24px;

      font-family: "Brandon Grotesque", sans-serif;
      font-size: 20px;
      line-height: 29px;

      padding: 10px 25px;

      i{
        margin-left: 15px;
      }
    }

    &-trustpilot{
      height: 65px;
      width: 250px;
      background-color: #FFFFFF;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .section {
    &-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 1200px) {
  .intro-image{
    margin-bottom: 15px;
  }
}


@media (max-width: 768px) {
  .heading {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title{
      text-align: center;
      font-size: 64px !important;
      line-height: 64px !important;
    }
    &-description{
      text-align: center;
    }
    &-trustpilot{
      right: calc(50% - 125.5px) !important;
    }
  }

  .product-section, .most-popular-section{

    .row{
      flex-wrap: nowrap;
      overflow: auto;
    }
  }
}

