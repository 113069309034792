.product-card {

  &-top {
    position: relative;
  }

  &-img {
    position: relative;
    padding: 30px;
    background: #F8F8F8;

    > a:not(:last-child) {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    img {
      max-height: 100%;
    }

    i{
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size:30px;
      color:#2E2C2F;
    }
  }

  &-brand {
    position: absolute;
    top: 14px;
    left: 14px;
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    z-index: 1;

    > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-gallery {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 15px;
    min-height: 34px;
    justify-content: center;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: 10px;
      }

      img {
        max-height: 100%;
      }
    }

    &-more {
      margin-left: 5px;
      font-weight: bold;
      line-height: 1.1;
      text-align: center;
      flex-grow: 0;
    }
  }

  &-name {
    font-family: "Brandon Grotesque", sans-serif;
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
    margin-top: 18px;
    text-align: center;

    > a {
      text-decoration: none;

      &:not(:hover) {
        color: #2E2C2F;
      }
    }
  }

  &-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  &-price {
    font-weight: bold;
  }

  &-rrp {
    margin-left: 10px;
  }
}

.sale-banner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $red;
  width: 80px;
  height: 80px;
  clip-path: polygon(100% 0, 100% 100%, 0 0);
  z-index: 10;

  &-text {
    position: absolute;
    top: 9px;
    right: 7px;
    color: $white;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    transform: rotate(45deg);
  }
}

@media screen and (max-width: map-get($grid-breakpoints, lg)) {
  .product-card {
    &-img {
      height: 225px;
    }
  }
}