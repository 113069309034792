@import "bootstrap/scss/accordion";

.accordion-item {
  --bs-accordion-active-bg: #fff !important;
  border: none;
  border-radius: 0 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button {
  padding: 15px 0 15px 0;
  color: $dark;
  font-family: $secondary-font-family;
  font-size: 30px;
  line-height: 43px;
  font-weight: bold;
  border-radius: 0 !important;
}

.accordion-header {
  border-top: 1px solid $dark;
}

.accordion-body {
  padding: 5px 0 15px 0;
  font-size: 18px;
  line-height: 27px;
}

.accordion-item:last-child {
  border-bottom: 1px solid $dark;
}

.accordion-button::after {
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #fff;
}