.products-section {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-family: "Brandon Grotesque", sans-serif;
      font-size: 35px;
      font-weight: bold;
    }
  }

  &-content {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .products-section {
    &-content {
      .row {
        flex-wrap: nowrap;
        overflow: auto;
      }
    }

    &-header{
      justify-content: center !important;
      &-actions{
        display:none;
      }
    }
  }
}