.password-reset {
  min-height: 400px;

  &-card {
    background-color: #F8F8F8;

    &-redirect {
      border: 1px solid $dark;
      border-radius: 38px;
      font-family: $secondary-font-family;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      padding: 7px 30px;

      &:hover {
        background-color: $dark;
        color: $white;
      }

    }

    &-submit {
      background-color: $orange;
      border: 1px solid $dark;
      border-radius: 38px;
      font-family: $secondary-font-family;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      padding: 7px 30px;

      &:hover {
        background-color: $dark;
        color: $white;
      }
    }

    label {
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
    }

    input {
      border: 1px solid $dark;
      border-radius: 0;
      padding: 12px;
      font-family: $primary-font-family;
      font-size: 18px;
      font-weight: normal;
      line-height: 27px;
    }

    &-back {
      font-family: $primary-font-family;
      font-size: 18px;
      line-height: 27px;
    }
  }
}
