.cart-sidebar {
  width: 550px !important;
  background-color: #F8F8F8 !important;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 30px 20px;
    i {
      font-size: 30px;
      line-height: 36px;
      color: $dark;
    }
  }

  &-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }

  &-submit {
    border-bottom: 1px solid $dark;
    button {
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
      color: $dark;
      width: 100%;
      background-color: $orange;
      padding: 7px 0;
      border: 1px solid $dark;
      border-radius: 38px;
      transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

      i {
        font-size: 20px;
        color: $dark;
        transition: color 0.3s ease;
      }

      &:hover {
        background-color: $dark;
        color: $white;

        i {
          color: $white;
        }
      }
    }
  }
}