.inner-content{

  p{
    font-family: $primary-font-family;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
  }

  ul{
    li{
      font-family: $primary-font-family;
      font-size: 18px;
      line-height: 27px;
      font-weight: normal;
    }
  }
}