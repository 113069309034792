.brand-card {
  $brandCard: &;

  position: relative;
  display: flex;
  height: 146px;
  background-image: url("/assets/images/brand-card-bg.jpeg");
  background-position: right bottom;
  background-size: cover;
  justify-content: center;
  align-items: center;

  &-overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #475B63;
    z-index: 0;
    opacity: .8;
    transition: opacity .3s ease-in-out;

    #{$brandCard}:hover & {
      opacity: .9;
    }
  }

  &-content {
    position: relative;
    display: flex;
    width: 60%;
    height: 60%;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}