@import 'bootstrap/scss/pagination';

.pagination-section{
  margin-top:60px;
  margin-bottom:80px;
  display: flex;
  justify-content: center;

  .product-pagination{
    display: flex;
    align-items: center;
    gap:5px;

    .page-link{
      background-color: #EEEEEE;
      border: 1px solid $dark;
      padding: 11px 19px;
      border-radius: 30px;
      font-family: $secondary-font-family;
      font-size:15px;
      line-height: 22px;
      font-weight: bold;

      i{
        font-size: 15px;
      }
    }

    .page-item.active{
      .page-link {
        background-color: #EEEEEE;
        border: 3px solid $dark;
        color: $dark;
      }
    }
  }
}

@media (max-width: 992px) {
  .pagination-section{
    .product-pagination{
      &-button-text{
        display: none;
      }

      .page-link{
        padding: 8px 16px;
      }
    }
  }
}