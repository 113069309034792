.cart-sidebar-product{
  display: flex;

  &-image{
    margin-right: 15px;

    img{
      padding: 14px;
      background-color:#EEEEEE;
    }
  }
}