.swiper-button-next,
.swiper-button-prev {
  color: #2E2C2F;
}

.swiper-button-next:after {
  content: '\f054';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
}

.swiper-button-prev:after {
  content: '\f053';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
}

.products-slider {
  &-nav{
    list-style: none;
    border-top: 1px solid $dark;

    &-item{
      a{
        font-size: 30px;
        font-family: $secondary-font-family;
        font-weight: bold;
        line-height: 1.4;
      }
    }
    :not(:last-child){
      border-right: 2px solid $dark;
      padding-right: 20px;
    }
    :not(:first-child){
      padding-left: 20px;
    }
  }
  &-section {

    .tab-link {
      text-decoration: none;
    }

    .tab-link.active {
      text-decoration: underline;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: #2E2C2F;
      font-size: 30px !important;
      top: 120px !important;
    }

    .swiper-button-next {
      right: 15px;
    }

    .swiper-button-prev {
      left: 15px;
    }
  }
}

@media (max-width: 768px) {

  .products-slider-section {
    display: none;
  }

  .breadcrumb-section {
    display: none;
  }

  .product {

    &-showcase {
      display: none;

      &-mobile {
        display: block;
      }
    }

    &-accordion {
      margin-bottom: 80px;
    }

  }
}