.create-account{
  &-card{
    background-color:#F8F8F8 ;

    &-input{
      width: 100%;

      label{
        width: 100%;
        font-family: $secondary-font-family;
        font-size: 20px;
        line-height: 29px;
        font-weight: bold;
        position: relative;
      }

      a{
        position: absolute;
        right:12px;
        bottom: 12px;
        i{
          font-size: 20px;
        }
      }
      input{
        width: 100%;
        border: 1px solid $dark;
        border-radius: 0;
        padding:12px;
        font-family: $primary-font-family;
        font-size: 18px;
        line-height: 27px;
        font-weight: normal;
      }

      .checkbox-label{
        font-family: $primary-font-family;
        font-size: 15px;
        line-height: 19px;
        font-weight: normal;
      }

      input[type='checkbox']{
        width: 20px;
        height: 20px;
        padding: 0;
      }
    }

    &-submit{
      padding: 7px 30px;
      background-color: $orange;
      border:1px solid $black;
      border-radius:38px;
      font-family: $secondary-font-family;
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;

      &:hover{
        background-color: $dark;
        color: $white;
      }
    }

    &-forgot{
      font-family: $primary-font-family;
      font-size: 18px;
      font-weight: normal;
      line-height: 27px;
    }
  }
}