:root {
  font-size: 15px;
}

.font-secondary{
  font-family: $secondary-font-family;
}

.font-primary{
  font-family: $primary-font-family;
}

.fc-white{
  color:$white;
}

.fc-dark{
  color:$dark;
}

.fc-orange{
  color:$orange;
}

.fc-red{
  color:$red;
}

.fc-green{
  color:$green;
}

.text-bg-red{
  background-color:$red;
}

.more-link, .less-link{
  color:inherit;
}

.container{
  --ok-gutter-x: 30px;
}

@media (max-width: 768px) {
  .pnotify {
    right: 0 !important;
    top: 0 !important;

    box-sizing: border-box;
  }
}